<a
  aria-label="Informationssymbol, klicken um weitere Informationen zu erhalten"
  class="nv-info-modal"
  tabindex="0"
  (click)="openDialog($event)"
>
  <i class="fad fa-info-circle"></i>
</a>

<nv-modal #modal>
  <ng-template modalContent>
    <ng-container *ngTemplateOutlet="lazyContent"></ng-container>
  </ng-template>
  <ng-content></ng-content>
</nv-modal>
