<button
  *ngIf="enableUserLogin && (showLoginButton$ | async)"
  class="btn btn-primary rounded-pill login-icon login-shake"
  [class.login-shake-dynamic]="shakeLoginButton$ | async"
  (click)="login()"
>
  <a
    aria-label="Kundenlogin - drücken Sie hier, um das Loginfenster zu öffnen, falls sie bereits einen existierenden Vertrag bei uns besitzen."
  >
    <i class="fas fa-user"></i>
  </a>
</button>
