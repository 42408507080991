<ng-template #dialogContent>
  <section class="my-3">
    <nv-container aria-modal="true">
      <div class="row">
        <div
          class="col-12 bay-padding"
          [ngClass]="{ 'col-lg-6 offset-lg-3': size === 'sm', 'col-lg-8 offset-lg-2': size === 'lg' }"
        >
          <nv-card class="overlay border-primary-1">
            <div class="modal-header" ngbAutoFocus>
              <button
                *ngIf="isClosable"
                type="button"
                class="btn btn-light nv-handler-close py-1"
                data-cy="close-btn"
                aria-label="Close"
                (click)="dismiss()"
              >
                <span aria-hidden="true" class="far fa-times"></span>
              </button>
            </div>
            <div class="modal-body">
              <ng-container *ngTemplateOutlet="lazyContent"></ng-container>
              <ng-content></ng-content>
            </div>
          </nv-card>
        </div>
      </div>
    </nv-container>
  </section>
</ng-template>
