<div *ngIf="enableUserLogin && showTeaser$ | async" class="login-teaser shadow mb-3 position-relative">
  <div class="row d-none d-md-flex">
    <!-- Desktop -->
    <div class="col-12 col-md-6 col-lg-7 d-flex flex-column justify-content-center">
      <h3 class="mb-0">{{ 'APP.LOGIN_TEASER.TITLE' | translate }}</h3>
      <p class="mb-0" id="login-description">
        {{ 'APP.LOGIN_TEASER.MESSAGE' | translate }}
        {{ 'APP.LOGIN_TEASER.DESKTOP.MESSAGE' | translate }}
      </p>
    </div>
    <div class="col-12 col-md-6 col-lg-5 d-flex align-items-center justify-content-around mt-3 mt-md-0">
      <button
        nv-button
        variant="outline"
        class="col-6"
        data-cy="login-button"
        (click)="login($event)"
        aria-describedby="login-description"
      >
        {{ 'APP.LOGIN_TEASER.BTN.LOGIN' | translate }}
      </button>
      <nv-link
        aria-label="Jetzt nicht Schaltfläche - Wenn Sie noch keinen Vertrag bei uns haben können sie dieses Element anklicken, damit das Anmelde-Fenster geschlossen wird. Sollten Sie dennoch auf das Anmelde-Fenster zurückgreifen müssen, so finden Sie anschließend ein Element zum Anmelden am Anfang der Seite."
        class="col-6 text-center"
        (click)="dismiss($event)"
      >
        {{ 'APP.LOGIN_TEASER.BTN.DISMISS' | translate }}
      </nv-link>
    </div>
  </div>
  <div class="row d-flex d-md-none">
    <!-- Mobile -->
    <div class="col-12 col-md-6 col-lg-7 d-flex flex-column justify-content-center">
      <h3 class="mb-0">{{ 'APP.LOGIN_TEASER.TITLE' | translate }}</h3>
      <p class="mb-0">
        {{ 'APP.LOGIN_TEASER.MESSAGE' | translate }}
        {{ 'APP.LOGIN_TEASER.MOBILE.MESSAGE_1' | translate }}
        <nv-link (click)="login($event)">{{ 'APP.LOGIN_TEASER.MOBILE.LINKTEXT' | translate }}</nv-link>
        {{ 'APP.LOGIN_TEASER.MOBILE.MESSAGE_2' | translate }}
      </p>
    </div>
  </div>
  <button type="button" class="btn btn-light nv-dismiss py-1" aria-label="Close" (click)="dismiss($event)">
    <span aria-hidden="true" class="far fa-times"></span>
  </button>
</div>
